var exports = {};

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

exports = _taggedTemplateLiteral, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;